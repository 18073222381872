import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const SputnikIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="460"
                height="340"
                viewBox="0 0 460 340"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M245.551 289.377C179.623 289.377 126.177 235.943 126.177 170.027C126.177 104.112 179.623 50.6772 245.551 50.6772C311.48 50.6772 364.925 104.112 364.925 170.027C364.925 235.943 311.48 289.377 245.551 289.377Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                    strokeDasharray="12.15"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M245.381 339.042C152.003 339.042 76.3052 263.359 76.3052 170C76.3052 76.6407 152.003 0.958008 245.381 0.958008C338.76 0.958008 414.458 76.6407 414.458 170C414.458 263.359 338.76 339.042 245.381 339.042Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                    strokeDasharray="12.15"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M99.1756 195.724L119.453 194.672L104.61 208.523L99.1756 195.724V195.724Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.5963 208.295L62.8479 199.701L76.5127 231.885L56.2611 240.478L42.5963 208.295Z"
                    fill="#80BFF8"
                    fillOpacity="0.2"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.4251 192.737L64.4219 190.617L84.1436 237.064L79.1463 239.184L59.4251 192.737Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.4251 192.737L64.4219 190.617L84.1436 237.064L79.1463 239.184L59.4251 192.737Z"
                    fill="#F8F8FA"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0438 138.326L34.7952 130.369L58.4697 186.126L39.7183 194.083L16.0438 138.326Z"
                    fill="#F8F8FA"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M64.0438 250.326L82.7952 242.369L106.47 298.126L87.7183 306.083L64.0438 250.326Z"
                    fill="#F8F8FA"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68.6268 200.519L72.0894 199.049L83.4024 225.692L79.9392 227.161L68.6268 200.519V200.519Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.3411 203.742L82.363 200.338L83.1281 202.14L75.1062 205.544L74.3411 203.742Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.3411 203.742L82.363 200.338L83.1281 202.14L75.1062 205.544L74.3411 203.742V203.742Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.8418 219.053L88.8637 215.649L89.6288 217.451L81.6069 220.855L80.8418 219.053Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.8418 219.053L88.8637 215.649L89.6288 217.451L81.6069 220.855L80.8418 219.053V219.053Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M69.995 238.335L73.4007 246.356L71.5987 247.12L68.1929 239.099L69.995 238.335V238.335Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.9915 216.457L42.596 208.295L56.2612 240.479L47.1895 238.12L37.9915 216.457V216.457Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.9917 216.457L32.3389 212.647L46.0041 244.832L47.1897 238.12L37.9917 216.457Z"
                    fill="#F8F8FA"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.6749 218.513L28.9034 217.348L32.9777 226.943L36.1485 221.983L34.6749 218.513V218.513Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.9329 235.607L36.1614 234.442L40.2357 244.037L43.4066 239.077L41.9329 235.607V235.607Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M73.0561 200.716L75.4669 199.693L78.7665 207.463L76.3555 208.486L73.0561 200.716Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M73.0561 200.716L75.4669 199.693L78.7665 207.463L76.3555 208.486L73.0561 200.716V200.716Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.5749 216.069L81.9857 215.046L85.2853 222.816L82.8743 223.839L79.5749 216.069Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.5749 216.069L81.9857 215.046L85.2853 222.816L82.8743 223.839L79.5749 216.069V216.069Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M65.9681 236.36L69.5689 234.832L71.2184 238.717L67.6176 240.245L65.9681 236.36Z"
                    fill="#F8F8FA"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M87.3751 208.366C81.2802 194.012 82.2317 179.42 89.0342 172.849C90.159 171.763 92.7362 169.941 94.7821 174.76C96.8274 179.578 114.692 221.651 116.555 226.04C118.419 230.428 115.648 231.79 114.087 231.845C104.632 232.174 93.4704 222.722 87.3751 208.366Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M87.3751 208.366C81.2802 194.012 82.2317 179.42 89.0342 172.849C90.159 171.763 92.7362 169.941 94.7821 174.76C96.8274 179.578 114.692 221.651 116.555 226.04C118.419 230.428 115.648 231.79 114.087 231.845C104.632 232.174 93.4704 222.722 87.3751 208.366Z"
                    fill="#80BFF8"
                    fillOpacity="0.3"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M81.7114 198.804L84.1221 197.781L92.6731 217.92L90.2624 218.943L81.7114 198.804Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M81.7114 198.804L84.1221 197.781L92.6731 217.92L90.2624 218.943L81.7114 198.804V198.804Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M121.288 197.003C119.833 197.62 118.152 196.941 117.534 195.486C116.917 194.031 117.596 192.35 119.051 191.733C120.506 191.115 122.187 191.794 122.805 193.249C123.423 194.705 122.744 196.385 121.288 197.003Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M81.2037 249.878L80.3104 247.774L82.4143 246.881L83.3076 248.985L81.2037 249.878Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M82.6553 253.296L81.7621 251.193L83.866 250.3L84.7592 252.404L82.6553 253.296Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M84.1067 256.715L83.2135 254.612L85.3174 253.719L86.2106 255.822L84.1067 256.715Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M85.5584 260.134L84.6652 258.03L86.7691 257.137L87.6623 259.241L85.5584 260.134Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M78.0474 251.217L77.1542 249.113L79.2581 248.22L80.1513 250.324L78.0474 251.217Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.4991 254.636L78.6058 252.532L80.7097 251.639L81.603 253.743L79.4991 254.636Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.9507 258.054L80.0575 255.951L82.1614 255.058L83.0546 257.162L80.9507 258.054Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M82.4024 261.473L81.5092 259.37L83.6131 258.477L84.5063 260.581L82.4024 261.473Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.8914 252.556L73.9982 250.452L76.1021 249.56L76.9953 251.663L74.8914 252.556Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M76.3431 255.975L75.4498 253.871L77.5537 252.979L78.447 255.082L76.3431 255.975Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M77.7947 259.394L76.9015 257.29L79.0054 256.397L79.8986 258.501L77.7947 259.394Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.2461 262.813L78.3529 260.709L80.4568 259.816L81.35 261.92L79.2461 262.813Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M71.7354 253.895L70.8422 251.792L72.9461 250.899L73.8393 253.003L71.7354 253.895Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M73.1868 257.314L72.2936 255.21L74.3975 254.318L75.2907 256.421L73.1868 257.314Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.6385 260.733L73.7453 258.629L75.8491 257.737L76.7424 259.84L74.6385 260.733Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M76.0901 264.152L75.1969 262.048L77.3008 261.155L78.194 263.259L76.0901 264.152Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68.5792 255.235L67.6859 253.131L69.7898 252.238L70.683 254.342L68.5792 255.235Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M70.0308 258.653L69.1376 256.55L71.2415 255.657L72.1347 257.761L70.0308 258.653Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M71.4825 262.072L70.5892 259.969L72.6931 259.076L73.5864 261.18L71.4825 262.072Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M72.9341 265.491L72.0409 263.387L74.1448 262.495L75.038 264.598L72.9341 265.491Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M88.806 267.782L87.9127 265.679L90.0166 264.786L90.9098 266.89L88.806 267.782Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M90.2576 271.201L89.3644 269.097L91.4683 268.205L92.3615 270.308L90.2576 271.201Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M91.7093 274.62L90.8161 272.516L92.9199 271.624L93.8132 273.727L91.7093 274.62Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M93.1607 278.039L92.2675 275.935L94.3714 275.042L95.2646 277.146L93.1607 278.039Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M85.65 269.122L84.7567 267.018L86.8606 266.125L87.7538 268.229L85.65 269.122Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M87.1014 272.54L86.2081 270.437L88.312 269.544L89.2053 271.648L87.1014 272.54Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M88.553 275.959L87.6598 273.856L89.7637 272.963L90.6569 275.067L88.553 275.959Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M90.0047 279.378L89.1115 277.274L91.2154 276.382L92.1086 278.485L90.0047 279.378Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M82.4937 270.461L81.6005 268.357L83.7044 267.465L84.5976 269.568L82.4937 270.461Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.9454 273.88L83.0521 271.776L85.156 270.883L86.0493 272.987L83.9454 273.88Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M85.397 277.299L84.5038 275.195L86.6077 274.302L87.5009 276.406L85.397 277.299Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M86.8487 280.717L85.9555 278.614L88.0593 277.721L88.9526 279.825L86.8487 280.717Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.3377 271.8L78.4445 269.696L80.5484 268.804L81.4416 270.907L79.3377 271.8Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.7894 275.219L79.8961 273.115L82 272.223L82.8932 274.326L80.7894 275.219Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M82.2408 278.638L81.3475 276.534L83.4514 275.641L84.3447 277.745L82.2408 278.638Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.6924 282.057L82.7992 279.953L84.9031 279.06L85.7963 281.164L83.6924 282.057Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M76.1814 273.14L75.2882 271.036L77.3921 270.143L78.2853 272.247L76.1814 273.14Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M77.6331 276.559L76.7399 274.455L78.8438 273.562L79.737 275.666L77.6331 276.559Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.0848 279.977L78.1915 277.873L80.2954 276.981L81.1887 279.084L79.0848 279.977Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.5364 283.396L79.6432 281.292L81.7471 280.4L82.6403 282.503L80.5364 283.396Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96.4083 285.687L95.515 283.584L97.6189 282.691L98.5121 284.795L96.4083 285.687Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M97.8599 289.106L96.9667 287.002L99.0706 286.11L99.9638 288.213L97.8599 289.106Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M99.3116 292.525L98.4183 290.421L100.522 289.528L101.415 291.632L99.3116 292.525Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M100.763 295.944L99.8698 293.84L101.974 292.947L102.867 295.051L100.763 295.944Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M93.2522 287.027L92.359 284.923L94.4629 284.03L95.3561 286.134L93.2522 287.027Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M94.7039 290.445L93.8107 288.342L95.9146 287.449L96.8078 289.553L94.7039 290.445Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96.1553 293.864L95.2621 291.76L97.366 290.868L98.2592 292.971L96.1553 293.864Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M97.607 297.283L96.7138 295.179L98.8176 294.287L99.7109 296.39L97.607 297.283Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M90.096 288.366L89.2028 286.262L91.3067 285.369L92.1999 287.473L90.096 288.366Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M91.5477 291.785L90.6544 289.681L92.7583 288.788L93.6515 290.892L91.5477 291.785Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M92.9993 295.204L92.1061 293.1L94.21 292.207L95.1032 294.311L92.9993 295.204Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M94.451 298.622L93.5578 296.519L95.6616 295.626L96.5549 297.73L94.451 298.622Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M86.94 289.705L86.0468 287.602L88.1507 286.709L89.0439 288.812L86.94 289.705Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M88.3917 293.124L87.4984 291.02L89.6023 290.127L90.4955 292.231L88.3917 293.124Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M89.8433 296.543L88.9501 294.439L91.054 293.546L91.9472 295.65L89.8433 296.543Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M91.2947 299.962L90.4015 297.858L92.5054 296.965L93.3986 299.069L91.2947 299.962Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.784 291.045L82.8908 288.941L84.9947 288.048L85.8879 290.152L83.784 291.045Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M85.2354 294.463L84.3422 292.36L86.4461 291.467L87.3393 293.57L85.2354 294.463Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M86.6871 297.882L85.7938 295.779L87.8977 294.886L88.791 296.989L86.6871 297.882Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M88.1387 301.301L87.2455 299.197L89.3494 298.304L90.2426 300.408L88.1387 301.301Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.5626 199.633L50.1568 191.612L48.3548 192.377L51.7606 200.398L53.5626 199.633V199.633Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.1863 203.901L55.787 202.373L54.1375 198.488L50.5367 200.016L52.1863 203.901Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.1863 203.901L55.787 202.373L54.1375 198.488L50.5367 200.016L52.1863 203.901Z"
                    fill="white"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.0431 183.554L53.9363 185.658L56.0402 184.765L55.147 182.661L53.0431 183.554Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M51.5914 180.135L52.4847 182.239L54.5886 181.346L53.6953 179.243L51.5914 180.135Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M50.1398 176.717L51.033 178.82L53.1369 177.927L52.2437 175.824L50.1398 176.717Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M48.6884 173.298L49.5816 175.402L51.6855 174.509L50.7923 172.405L48.6884 173.298Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.8871 184.894L50.7803 186.997L52.8842 186.104L51.991 184.001L49.8871 184.894Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M48.4354 181.475L49.3287 183.578L51.4326 182.685L50.5393 180.582L48.4354 181.475Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M46.9838 178.056L47.877 180.16L49.9809 179.267L49.0877 177.163L46.9838 178.056Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.5321 174.637L46.4253 176.741L48.5292 175.848L47.636 173.744L45.5321 174.637Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M46.7308 186.233L47.6241 188.336L49.728 187.444L48.8347 185.34L46.7308 186.233Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.2794 182.814L46.1727 184.918L48.2765 184.025L47.3833 181.921L45.2794 182.814Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.8278 179.395L44.721 181.499L46.8249 180.606L45.9317 178.502L43.8278 179.395Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.3761 175.976L43.2693 178.08L45.3732 177.187L44.48 175.083L42.3761 175.976Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.5748 187.572L44.4681 189.676L46.572 188.783L45.6787 186.679L43.5748 187.572Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.1232 184.153L43.0164 186.257L45.1203 185.364L44.2271 183.26L42.1232 184.153Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.6715 180.735L41.5647 182.838L43.6686 181.945L42.7754 179.842L40.6715 180.735Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.2201 177.316L40.1133 179.419L42.2172 178.527L41.324 176.423L39.2201 177.316Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.4186 188.911L41.3118 191.015L43.4157 190.122L42.5225 188.019L40.4186 188.911Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M38.9672 185.493L39.8604 187.596L41.9643 186.704L41.0711 184.6L38.9672 185.493Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.5155 182.074L38.4087 184.177L40.5126 183.285L39.6194 181.181L37.5155 182.074Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.0639 178.655L36.9571 180.759L39.061 179.866L38.1677 177.762L36.0639 178.655Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.4408 165.649L46.334 167.753L48.4379 166.86L47.5447 164.756L45.4408 165.649Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.9891 162.23L44.8824 164.334L46.9863 163.441L46.093 161.338L43.9891 162.23Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.5375 158.812L43.4307 160.915L45.5346 160.023L44.6414 157.919L42.5375 158.812Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.0858 155.393L41.9791 157.496L44.0829 156.604L43.1897 154.5L41.0858 155.393Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.2846 166.988L43.1778 169.092L45.2817 168.199L44.3885 166.096L42.2846 166.988Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.8331 163.57L41.7264 165.673L43.8303 164.781L42.937 162.677L40.8331 163.57Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.3815 160.151L40.2747 162.255L42.3786 161.362L41.4854 159.258L39.3815 160.151Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.9298 156.732L38.823 158.836L40.9269 157.943L40.0337 155.839L37.9298 156.732Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.1286 168.328L40.0218 170.432L42.1257 169.539L41.2324 167.435L39.1286 168.328Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.6769 164.909L38.5701 167.013L40.674 166.12L39.7808 164.016L37.6769 164.909Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.2252 161.49L37.1185 163.594L39.2223 162.701L38.3291 160.597L36.2252 161.49Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.7738 158.071L35.667 160.175L37.7709 159.282L36.8777 157.179L34.7738 158.071Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.9725 169.667L36.8658 171.771L38.9697 170.878L38.0764 168.774L35.9725 169.667Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.5209 166.248L35.4141 168.352L37.518 167.459L36.6248 165.355L34.5209 166.248Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.0692 162.83L33.9625 164.933L36.0663 164.04L35.1731 161.937L33.0692 162.83Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.6176 159.411L32.5108 161.515L34.6147 160.622L33.7215 158.518L31.6176 159.411Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.8163 171.007L33.7095 173.11L35.8134 172.217L34.9202 170.114L32.8163 171.007Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.3649 167.588L32.2581 169.691L34.362 168.799L33.4688 166.695L31.3649 167.588Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.9132 164.169L30.8064 166.273L32.9103 165.38L32.0171 163.276L29.9132 164.169Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.4616 160.75L29.3548 162.854L31.4587 161.961L30.5655 159.857L28.4616 160.75Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.8385 147.744L38.7317 149.848L40.8356 148.955L39.9424 146.852L37.8385 147.744Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.3869 144.325L37.2801 146.429L39.384 145.536L38.4907 143.433L36.3869 144.325Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.9352 140.907L35.8284 143.01L37.9323 142.118L37.0391 140.014L34.9352 140.907Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.4835 137.488L34.3768 139.592L36.4806 138.699L35.5874 136.595L33.4835 137.488Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.6823 149.084L35.5755 151.187L37.6794 150.295L36.7862 148.191L34.6823 149.084Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.2306 145.665L34.1238 147.768L36.2277 146.876L35.3345 144.772L33.2306 145.665Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.7792 142.246L32.6724 144.35L34.7763 143.457L33.8831 141.353L31.7792 142.246Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.3275 138.827L31.2208 140.931L33.3246 140.038L32.4314 137.934L30.3275 138.827Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.5263 150.423L32.4195 152.527L34.5234 151.634L33.6301 149.53L31.5263 150.423Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.0746 147.004L30.9678 149.108L33.0717 148.215L32.1785 146.111L30.0746 147.004Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.6229 143.585L29.5162 145.689L31.6201 144.796L30.7268 142.693L28.6229 143.585Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.1713 140.166L28.0645 142.27L30.1684 141.377L29.2752 139.274L27.1713 140.166Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.37 151.762L29.2632 153.866L31.3671 152.973L30.4739 150.869L28.37 151.762Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.9186 148.343L27.8118 150.447L29.9157 149.554L29.0225 147.451L26.9186 148.343Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.4669 144.925L26.3602 147.028L28.464 146.135L27.5708 144.032L25.4669 144.925Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.0153 141.506L24.9085 143.61L27.0124 142.717L26.1192 140.613L24.0153 141.506Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.214 153.102L26.1072 155.205L28.2111 154.312L27.3179 152.209L25.214 153.102Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.7623 149.683L24.6556 151.787L26.7595 150.894L25.8662 148.79L23.7623 149.683Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.3107 146.264L23.2039 148.368L25.3078 147.475L24.4146 145.371L22.3107 146.264Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.8593 142.845L21.7525 144.949L23.8564 144.056L22.9632 141.952L20.8593 142.845Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M129.534 189.762C127.908 185.932 127.245 182.55 128.053 182.207C128.861 181.864 130.834 184.691 132.46 188.521C134.086 192.351 134.75 195.733 133.942 196.076C133.134 196.419 131.161 193.592 129.534 189.762Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M129.534 189.762C127.908 185.932 127.245 182.55 128.053 182.207C128.861 181.864 130.834 184.691 132.46 188.521C134.086 192.351 134.75 195.733 133.942 196.076C133.134 196.419 131.161 193.592 129.534 189.762Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M135.965 187.033C132.715 179.381 131.39 172.622 133.004 171.937C134.618 171.252 138.561 176.9 141.81 184.553C145.059 192.206 146.385 198.965 144.771 199.65C143.156 200.335 139.214 194.686 135.965 187.033Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M135.965 187.033C132.715 179.381 131.39 172.622 133.004 171.937C134.618 171.252 138.561 176.9 141.81 184.553C145.059 192.206 146.385 198.965 144.771 199.65C143.156 200.335 139.214 194.686 135.965 187.033Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    opacity="0.35"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M146.348 182.627C141.993 172.372 140.217 163.314 142.38 162.396C144.543 161.478 149.827 169.048 154.181 179.303C158.536 189.559 160.312 198.617 158.149 199.535C155.986 200.453 150.702 192.883 146.348 182.627Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M146.348 182.627C141.993 172.372 140.217 163.314 142.38 162.396C144.543 161.478 149.827 169.048 154.181 179.303C158.536 189.559 160.312 198.617 158.149 199.535C155.986 200.453 150.702 192.883 146.348 182.627Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                    strokeLinejoin="round"
                />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M66.448 86.6288C63.7769 85.873 61.6218 85.8302 61.3384 86.6087C61.0547 87.3881 62.7372 88.7428 65.2738 89.8815C64.5429 92.4954 64.4903 94.5836 65.231 94.8531C65.9716 95.1227 67.2736 93.4893 68.3938 91.0171C71.069 91.7753 73.2285 91.819 73.5122 91.0396C73.7956 90.2611 72.1171 88.9086 69.5851 87.7707C70.3481 85.101 70.4137 82.953 69.6619 82.6793C68.91 82.4057 67.5795 84.0933 66.448 86.6288Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M119.334 329.463L118.983 329.849C118.08 330.842 116.544 330.915 115.551 330.013C115.155 329.653 114.889 329.173 114.794 328.648L114.701 328.136L114.228 327.922C113.004 327.371 112.459 325.932 113.011 324.708C113.23 324.221 113.604 323.82 114.075 323.566L114.533 323.32L114.59 322.802C114.737 321.468 115.937 320.505 117.271 320.652C117.802 320.71 118.299 320.942 118.686 321.311L119.062 321.672L119.572 321.566C120.886 321.293 122.172 322.137 122.445 323.451C122.554 323.974 122.487 324.519 122.255 325L122.029 325.469L122.287 325.92C122.953 327.086 122.548 328.57 121.382 329.236C120.918 329.501 120.38 329.605 119.85 329.534L119.334 329.463Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M433.523 120.324C432.438 120.969 431.084 120.09 431.232 118.837C430.283 118.004 430.701 116.445 431.939 116.198C432.438 115.038 434.05 114.954 434.668 116.055C435.925 116.171 436.503 117.678 435.647 118.605C435.925 119.837 434.67 120.852 433.523 120.324Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M98.8186 38.832L97.9855 38.9599C95.9948 39.2655 94.5178 37.1558 95.486 35.3898L95.8908 34.6515L95.5122 33.9C94.606 32.1012 96.156 30.0441 98.135 30.4192L98.9622 30.5761L99.5611 29.9828C100.992 28.5656 103.427 29.404 103.682 31.4017L103.788 32.2378L104.537 32.6236C106.327 33.5463 106.282 36.1216 104.461 36.9812L103.7 37.3403L103.564 38.1713C103.24 40.159 100.777 40.9121 99.3964 39.4457L98.8186 38.832Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M397.189 306.067L396.656 305.932C395.356 305.601 394.57 304.278 394.901 302.977C395.032 302.46 395.331 302 395.751 301.669L396.182 301.329L396.146 300.781C396.058 299.442 397.073 298.286 398.412 298.198C398.946 298.163 399.476 298.305 399.92 298.602L400.377 298.907L400.888 298.703C402.134 298.206 403.548 298.814 404.045 300.061C404.242 300.557 404.271 301.105 404.126 301.619L403.977 302.149L404.328 302.571C405.186 303.603 405.045 305.135 404.013 305.993C403.602 306.335 403.089 306.531 402.555 306.552L402.007 306.574L401.714 307.038C400.998 308.173 399.497 308.512 398.362 307.796C397.91 307.51 397.565 307.084 397.38 306.583L397.189 306.067Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M440.821 146.617C439.85 147.424 438.375 146.768 438.325 145.506C437.258 144.832 437.427 143.227 438.611 142.79C438.922 141.566 440.501 141.23 441.283 142.222C442.543 142.139 443.35 143.537 442.649 144.587C443.117 145.76 442.036 146.959 440.821 146.617Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M245.18 220.936C277.718 220.936 304.095 194.565 304.095 162.034C304.095 129.504 277.718 103.132 245.18 103.132C212.643 103.132 186.266 129.504 186.266 162.034C186.266 194.565 212.643 220.936 245.18 220.936Z"
                    fill="#80BFF8"
                />
                <path
                    opacity="0.8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M245.18 220.936C277.718 220.936 304.095 194.565 304.095 162.034C304.095 129.504 277.718 103.132 245.18 103.132C212.643 103.132 186.266 129.504 186.266 162.034C186.266 194.565 212.643 220.936 245.18 220.936Z"
                    stroke="#80BFF8"
                    strokeWidth="3"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M245.904 141.383C241.705 141.383 238.439 142.665 236.106 145.23C233.774 147.678 232.666 150.942 232.666 155.022H237.273C237.273 152.166 237.914 149.951 239.197 148.319C240.597 146.395 242.755 145.463 245.671 145.463C248.12 145.463 250.045 146.104 251.386 147.503C252.669 148.785 253.369 150.592 253.369 152.923C253.369 154.555 252.786 156.071 251.62 157.528C251.27 157.994 250.57 158.694 249.637 159.626C246.487 162.424 244.563 164.639 243.746 166.387C243.046 167.845 242.697 169.535 242.697 171.458V172.799H247.362V171.458C247.362 169.885 247.712 168.486 248.47 167.203C249.053 166.154 249.928 165.105 251.211 163.998C253.777 161.725 255.352 160.209 255.935 159.51C257.393 157.586 258.151 155.313 258.151 152.69C258.151 149.193 257.043 146.454 254.885 144.472C252.611 142.374 249.637 141.383 245.904 141.383ZM245.029 176.879C244.038 176.879 243.221 177.17 242.522 177.87C241.822 178.511 241.53 179.327 241.53 180.318C241.53 181.309 241.822 182.125 242.522 182.824C243.221 183.465 244.038 183.815 245.029 183.815C246.021 183.815 246.837 183.465 247.537 182.824C248.237 182.183 248.587 181.367 248.587 180.318C248.587 179.327 248.237 178.511 247.595 177.87C246.896 177.17 246.021 176.879 245.029 176.879Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M223.82 133.463C229.636 130.106 240.19 126.151 236.833 120.337C233.475 114.522 221.124 115.367 215.309 118.724C209.493 122.081 203.288 131.947 206.646 137.761C210.004 143.576 218.004 136.82 223.82 133.463Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M250.237 120.143C252.924 120.143 255.101 117.966 255.101 115.281C255.101 112.595 252.924 110.418 250.237 110.418C247.551 110.418 245.374 112.595 245.374 115.281C245.374 117.966 247.551 120.143 250.237 120.143Z"
                    fill="#F8F8FA"
                />
            </svg>
        )}
        {...props}
    />
)
